import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "reactstrap"
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
} from "../../../helpers/Utils"
import { useLocation, useHistory } from "react-router-dom"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import DeadList from "./deadList"
import MultipleChoice from "../../Filter/MultipleChoice"
import InputFilter from "../../Filter/SearchFilter"
import DateFilter from "../../Filter/DateFilter"
import LeadsFilter from "../../LeadFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AssignLabels from "../../Assign-Labels"
import CampaignModel from "../../Campaigns"
import Select from "react-select"

let selectedUserId = []

const DeadLeads = () => {
  const history = useHistory()
  const [modal_standard, setmodal_standard] = useState(false)
  const [campaign_model, setcampaign_model] = useState(false)
  const [assignLabel, setAssignLabel] = useState(false)
  const [id_filter, setid_filter] = useState(false)
  const [name_filter, setname_filter] = useState(false)
  const [contact_filter, setcontact_filter] = useState(false)
  const [leadsource_filter, setleadsource_filter] = useState(false)
  const [tag_filter, settag_filter] = useState(false)
  const [label_filter, setlabel_filter] = useState(false)
  const [leadholder_filter, setleadholder_filter] = useState(false)
  const [dead_filter, setdead_filter] = useState(false)
  const [date_filter, setdate_filter] = useState(false)
  const [message_filter, setmessage_filter] = useState(false)
  const [sort, setSort] = useState(false)
  const [sort2, setSort2] = useState(false)
  const [sort3, setSort3] = useState(false)
  const [sort4, setSort4] = useState(false)
  const [sort5, setSort5] = useState(false)
  const [sort6, setSort6] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [followup, setFollowup] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [allDead, setAllDead] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState("")
  const [blur, setBlur] = useState(false)
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert2, setconfirm_alert2] = useState(false)
  const [check, setCheck] = useState(0)
  const [lead_filter, setlead_filter] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [transferNew, setTransferNew] = useState("0")
  const [hideHistory, setHideHistory] = useState("0")
  const [modal4, setModal4] = useState(false)

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()
  let url1 = query.get("id")
  let url2 = query.get("dead_reason_id")
  let url3 = query.get("lead_source_id")

  const getDeadLeads = id => {
    setFollowup([])
    setError("")
    setLoader(<Loader />)
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/dead-lead?&per_page=${
      localStorage.getItem("PerPage") !== null
        ? localStorage.getItem("PerPage")
        : ""
    }&id=${url1 !== null ? url1 : ""}&dead_reason_id=${
      url2 !== null ? url2 : ""
    }&lead_source_id=${url3 !== null ? url3 : ""}&page=${localStorage.getItem(
      "current_page"
    )}`
    if (id !== undefined) {
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/dead-lead${id}`
    }
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setTotal(res.data.data.total)
          setFollowup(res.data.data.data)
          setAllPages(res.data.data)
          setBlur(false)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setFollowup([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(
        `${e}&id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
            &dead_reason_id=${
              localStorage.getItem("deadStatus") !== null
                ? localStorage.getItem("deadStatus")
                : ""
            }
            &leadholder_id=${
              localStorage.getItem("leadHolder") !== null
                ? localStorage.getItem("leadHolder")
                : ""
            }
            &contact=${
              localStorage.getItem("leadContact") !== null
                ? localStorage.getItem("leadContact")
                : ""
            } 
            &name=${
              localStorage.getItem("leadName") !== null
                ? localStorage.getItem("leadName")
                : ""
            }
            &lead_label_id=${
              localStorage.getItem("leadLabel") !== null
                ? localStorage.getItem("leadLabel")
                : ""
            }
            &tag_id=${
              localStorage.getItem("leadTag") !== null
                ? localStorage.getItem("leadTag")
                : ""
            }
            &lead_source_id=${
              localStorage.getItem("leadSource") !== null
                ? localStorage.getItem("leadSource")
                : ""
            }
            &followup_start=${
              getFilterDate() && getFilterDate().from !== null
                ? getFilterDate().from
                : ""
            }
            &followup_to=${
              getFilterDate() && getFilterDate().to !== null
                ? getFilterDate().to
                : ""
            }
            &lastmessage=${
              localStorage.getItem("leadMessage") !== null
                ? localStorage.getItem("leadMessage")
                : ""
            }
            &sort=${
              localStorage.getItem("leadSort") !== null
                ? localStorage.getItem("leadSort")
                : ""
            }
            &firm_name=${
              localStorage.getItem("leadFirmName") !== null
                ? localStorage.getItem("leadFirmName")
                : ""
            }
             &no_updation_since=${
               localStorage.getItem("leadUpdateSince") !== null
                 ? localStorage.getItem("leadUpdateSince")
                 : ""
             }
             &flag=${
               localStorage.getItem("leadFlag") !== null
                 ? localStorage.getItem("leadFlag")
                 : ""
             }&company_pool=${
          localStorage.getItem("CompanyPool") !== null
            ? localStorage.getItem("CompanyPool")
            : ""
        }&custom_filters=${
          localStorage.getItem("leadfields_filter") !== null
            ? localStorage.getItem("leadfields_filter")
            : ""
        }
            &assigned=${
              localStorage.getItem("leadAssigned") !== null
                ? localStorage.getItem("leadAssigned")
                : ""
            }
             &transfer_request=${
               localStorage.getItem("leadTransfer") !== null
                 ? localStorage.getItem("leadTransfer")
                 : ""
             }
             &from_date=${
               localStorage.getItem("leadCreated_at") !== ""
                 ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
                 : ""
             }
         &to_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
             : ""
         }
            &mature_start_date=${
              localStorage.getItem("leadMatured_at") !== ""
                ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
                : ""
            }
         &mature_end_date=${
           localStorage.getItem("leadMatured_at") !== ""
             ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
             : ""
         }
            &assigned_from_date=${
              localStorage.getItem("leadAssigned_at") !== ""
                ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
                : ""
            }
         &assigned_to_date=${
           localStorage.getItem("leadAssigned_at") !== ""
             ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
             : ""
         }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setFollowup(res.data.data.data)
          localStorage.setItem("current_page", res.data.data.current_page)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const getAllDead = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/dead-status?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllDead(res.data.data.data)
        } else {
        }
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "id") {
      localStorage.setItem("leadID", e)
    }
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "contact") {
      localStorage.setItem("leadContact", e)
    }
    if (type == "leadsource") {
      localStorage.setItem("leadSource", e)
    }
    if (type == "tag") {
      localStorage.setItem("leadTag", e)
    }
    if (type == "label") {
      localStorage.setItem("leadLabel", e)
    }
    if (type == "leadholder") {
      localStorage.setItem("leadHolder", e)
    }
    if (type == "d-status") {
      localStorage.setItem("deadStatus", e)
    }
    if (type == "date") {
      setFilterDate(e)
    }
    if (type == "message") {
      localStorage.setItem("leadMessage", e)
    }
    if (type == "sort") {
      localStorage.setItem("leadSort", e)
    }
    setSearchedCount("")
    setFollowup([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/dead-lead?id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
         &dead_reason_id=${
           localStorage.getItem("deadStatus") !== null
             ? localStorage.getItem("deadStatus")
             : ""
         }
         &leadholder_id=${
           localStorage.getItem("leadHolder") !== null
             ? localStorage.getItem("leadHolder")
             : ""
         }
         &contact=${
           localStorage.getItem("leadContact") !== null
             ? localStorage.getItem("leadContact")
             : ""
         } 
         &name=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &lead_label_id=${
           localStorage.getItem("leadLabel") !== null
             ? localStorage.getItem("leadLabel")
             : ""
         }
         &tag_id=${
           localStorage.getItem("leadTag") !== null
             ? localStorage.getItem("leadTag")
             : ""
         }
         &lead_source_id=${
           localStorage.getItem("leadSource") !== null
             ? localStorage.getItem("leadSource")
             : ""
         }
         &followup_start=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &followup_to=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &lastmessage=${
           localStorage.getItem("leadMessage") !== null
             ? localStorage.getItem("leadMessage")
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }
         &firm_name=${
           localStorage.getItem("leadFirmName") !== null
             ? localStorage.getItem("leadFirmName")
             : ""
         }
         &no_updation_since=${
           localStorage.getItem("leadUpdateSince") !== null
             ? localStorage.getItem("leadUpdateSince")
             : ""
         }
         &flag=${
           localStorage.getItem("leadFlag") !== null
             ? localStorage.getItem("leadFlag")
             : ""
         }&company_pool=${
          localStorage.getItem("CompanyPool") !== null
            ? localStorage.getItem("CompanyPool")
            : ""
        }&custom_filters=${
          localStorage.getItem("leadfields_filter") !== null
            ? localStorage.getItem("leadfields_filter")
            : ""
        }
            &assigned=${
              localStorage.getItem("leadAssigned") !== null
                ? localStorage.getItem("leadAssigned")
                : ""
            }
         &transfer_request=${
           localStorage.getItem("leadTransfer") !== null
             ? localStorage.getItem("leadTransfer")
             : ""
         }
         &from_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
             : ""
         }
         &to_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
             : ""
         }
            &mature_start_date=${
              localStorage.getItem("leadMatured_at") !== ""
                ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
                : ""
            }
         &mature_end_date=${
           localStorage.getItem("leadMatured_at") !== ""
             ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
             : ""
         }
            &assigned_from_date=${
              localStorage.getItem("leadAssigned_at") !== ""
                ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
                : ""
            }
         &assigned_to_date=${
           localStorage.getItem("leadAssigned_at") !== ""
             ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setFollowup(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setname_filter(false)
          setid_filter(false)
          setcontact_filter(false)
          setleadsource_filter(false)
          settag_filter(false)
          setlabel_filter(false)
          setdate_filter(false)
          setmessage_filter(false)
          setleadholder_filter(false)
          setdead_filter(false)
          setlead_filter(false)
        } else {
          setError(res.data.message)
          setlead_filter(false)
        }
      })
  }

  const getFilteredLeads = () => {
    setError("")
    setSearchedCount("")
    setFollowup([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/dead-lead?id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
         &dead_reason_id=${
           localStorage.getItem("deadStatus") !== null
             ? localStorage.getItem("deadStatus")
             : ""
         }
         &leadholder_id=${
           localStorage.getItem("leadHolder") !== null
             ? localStorage.getItem("leadHolder")
             : ""
         }
         &contact=${
           localStorage.getItem("leadContact") !== null
             ? localStorage.getItem("leadContact")
             : ""
         } 
         &name=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &lead_label_id=${
           localStorage.getItem("leadLabel") !== null
             ? localStorage.getItem("leadLabel")
             : ""
         }
         &tag_id=${
           localStorage.getItem("leadTag") !== null
             ? localStorage.getItem("leadTag")
             : ""
         }
         &lead_source_id=${
           localStorage.getItem("leadSource") !== null
             ? localStorage.getItem("leadSource")
             : ""
         }
         &followup_start=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &followup_to=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &lastmessage=${
           localStorage.getItem("leadMessage") !== null
             ? localStorage.getItem("leadMessage")
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }
         &firm_name=${
           localStorage.getItem("leadFirmName") !== null
             ? localStorage.getItem("leadFirmName")
             : ""
         }
         &no_updation_since=${
           localStorage.getItem("leadUpdateSince") !== null
             ? localStorage.getItem("leadUpdateSince")
             : ""
         }
         &flag=${
           localStorage.getItem("leadFlag") !== null
             ? localStorage.getItem("leadFlag")
             : ""
         }&company_pool=${
          localStorage.getItem("CompanyPool") !== null
            ? localStorage.getItem("CompanyPool")
            : ""
        }&custom_filters=${
          localStorage.getItem("leadfields_filter") !== null
            ? localStorage.getItem("leadfields_filter")
            : ""
        }
            &assigned=${
              localStorage.getItem("leadAssigned") !== null
                ? localStorage.getItem("leadAssigned")
                : ""
            }
         &transfer_request=${
           localStorage.getItem("leadTransfer") !== null
             ? localStorage.getItem("leadTransfer")
             : ""
         }
         &from_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
             : ""
         }
         &to_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
             : ""
         }
            &mature_start_date=${
              localStorage.getItem("leadMatured_at") !== ""
                ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
                : ""
            }
         &mature_end_date=${
           localStorage.getItem("leadMatured_at") !== ""
             ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
             : ""
         }
            &assigned_from_date=${
              localStorage.getItem("leadAssigned_at") !== ""
                ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
                : ""
            }
         &assigned_to_date=${
           localStorage.getItem("leadAssigned_at") !== ""
             ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         &page=${localStorage.getItem("current_page")}
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setFollowup(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setError("")
        } else {
          setError(res.data.message)
        }
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const exportLead = e => {
    let Ids = []
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/lead-export`
    if (e == "All") {
      Ids = []
    } else if (e == "Selected") {
      Ids = leadsSelectedIds
    } else {
      Ids = []
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/lead-export?id=${
        localStorage.getItem("leadID") !== null
          ? localStorage.getItem("leadID")
          : ""
      }
        &dead_reason_id=${
          localStorage.getItem("deadStatus") !== null
            ? localStorage.getItem("deadStatus")
            : ""
        }
        &leadholder_id=${
          localStorage.getItem("leadHolder") !== null
            ? localStorage.getItem("leadHolder")
            : ""
        }
        &contact=${
          localStorage.getItem("leadContact") !== null
            ? localStorage.getItem("leadContact")
            : ""
        } 
        &name=${
          localStorage.getItem("leadName") !== null
            ? localStorage.getItem("leadName")
            : ""
        }
        &lead_label_id=${
          localStorage.getItem("leadLabel") !== null
            ? localStorage.getItem("leadLabel")
            : ""
        }
        &tag_id=${
          localStorage.getItem("leadTag") !== null
            ? localStorage.getItem("leadTag")
            : ""
        }
        &lead_source_id=${
          localStorage.getItem("leadSource") !== null
            ? localStorage.getItem("leadSource")
            : ""
        }
        &followup_start=${
          getFilterDate() && getFilterDate().from !== null
            ? getFilterDate().from
            : ""
        }
        &followup_to=${
          getFilterDate() && getFilterDate().to !== null
            ? getFilterDate().to
            : ""
        }
        &lastmessage=${
          localStorage.getItem("leadMessage") !== null
            ? localStorage.getItem("leadMessage")
            : ""
        }
        &sort=${
          localStorage.getItem("leadSort") !== null
            ? localStorage.getItem("leadSort")
            : ""
        }
        &firm_name=${
          localStorage.getItem("leadFirmName") !== null
            ? localStorage.getItem("leadFirmName")
            : ""
        }
        &no_updation_since=${
          localStorage.getItem("leadUpdateSince") !== null
            ? localStorage.getItem("leadUpdateSince")
            : ""
        }
        &flag=${
          localStorage.getItem("leadFlag") !== null
            ? localStorage.getItem("leadFlag")
            : ""
        }&company_pool=${
        localStorage.getItem("CompanyPool") !== null
          ? localStorage.getItem("CompanyPool")
          : ""
      }&custom_filters=${
        localStorage.getItem("leadfields_filter") !== null
          ? localStorage.getItem("leadfields_filter")
          : ""
      }
            &assigned=${
              localStorage.getItem("leadAssigned") !== null
                ? localStorage.getItem("leadAssigned")
                : ""
            }
        &transfer_request=${
          localStorage.getItem("leadTransfer") !== null
            ? localStorage.getItem("leadTransfer")
            : ""
        }
        &from_date=${
          localStorage.getItem("leadCreated_at") !== ""
            ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
            : ""
        }
        &to_date=${
          localStorage.getItem("leadCreated_at") !== ""
            ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
            : ""
        }`
    }
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${url}`,
        {
          ids: Ids,
          followup_type: "dead",
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const deleteLeads = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-deleted`,
        {
          ids: leadsSelectedIds,
          is_deleted: check,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCheck(0)
          setLeadsSelectedIds([])
          getSearchedLeads()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const updateFlag = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/update-flag`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getFilteredLeads()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const getCheckValue = check => {
    if (check.checked) {
      setTransferNew("1")
    } else {
      setTransferNew("0")
      setHideHistory("0")
    }
  }

  const getCheckValue2 = check => {
    if (check.checked) {
      setHideHistory("1")
    } else {
      setHideHistory("0")
    }
  }

  const options = []

  if (JSON.parse(localStorage.getItem("All-User"))) {
    options.push({ value: "0", label: "Company pool" })
    JSON.parse(localStorage.getItem("All-User")).map(item => {
      options.push({
        value: item.id,
        label: item.name,
      })
    })
  }

  const getSelectedId = e => {
    selectedUserId = []
    e?.map(item => selectedUserId.push(item.value))
  }

  const transferLeads = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead-transfer`,
        {
          lead_ids: leadsSelectedIds,
          user_id: selectedUserId,
          transfer_as_new: transferNew,
          hide_lead_history: hideHistory,
          type: value.type,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          getSearchedLeads()
          setLeadsSelectedIds([])
          setTransferNew("0")
          setHideHistory("0")
          setLoading(false)
          setModal2(false)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          setLoading(false)
          setTransferNew("0")
          setHideHistory("0")
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const transferRequest = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead-transfer-request`,
        {
          lead_ids: leadsSelectedIds,
          user_id: value.user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          getFilteredLeads()
          setLeadsSelectedIds([])
          setLoading(false)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          setLoading(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const clearLocal = () => {
    localStorage.setItem("leadHolder", "")
    localStorage.setItem("leadLabel", "")
    localStorage.setItem("leadStatus", "")
    localStorage.setItem("leadTag", "")
    localStorage.setItem("leadName", "")
    localStorage.setItem("leadContact", "")
    localStorage.setItem("leadSource", "")
    localStorage.setItem("leadAmount", "")
    setFilterDate({
      from: "",
      to: "",
    })
    localStorage.setItem("leadMessage", "")
    localStorage.setItem("followupStatus", "")
    localStorage.setItem("matureStatus", "")
    localStorage.setItem("deadReason", "")
    localStorage.setItem("deadStatus", "")
    localStorage.setItem("latestMessage", "")
    localStorage.setItem("leadRole", "")
    localStorage.setItem("leadEmail", "")
    localStorage.setItem("leadStatus2", "")
    localStorage.setItem("leadDay", "")
    localStorage.setItem("leadStatus3", "")
    localStorage.setItem("leadmaturedby", "")
    localStorage.setItem("ledgerType", "")
    localStorage.setItem("offerType", "")
    localStorage.setItem("PrductGST", "")
    localStorage.setItem("OrderStatus", "")
    localStorage.setItem("OrderPstatus", "")
    localStorage.setItem("leadSort", "")
    localStorage.setItem("leadID", "")
    localStorage.setItem("leadTransfer", "")
    localStorage.setItem("leadFlag", "")
    localStorage.setItem("leadUpdateSince", "")
    localStorage.setItem("leadOrderSince", "")
    localStorage.setItem("leadFirmName", "")
    localStorage.setItem("leadCreated_at", "")
    localStorage.setItem("leadMatured_at", "")
    localStorage.setItem(
      "leadAssigned_at",
      JSON.stringify({
        startDate: "",
        endDate: "",
      })
    )
    localStorage.setItem("current_page", "")
    localStorage.setItem("leadAssigned", "")
    localStorage.setItem("CompanyPool", "")
  }

  useEffect(() => {
    history.listen(location => {
      if (history.location.search !== "") {
        getDeadLeads(location.search)
      }
    })
    getDeadLeads()
    getAllDead()
  }, [])

  return (
    <>
      {lead_filter ? (
        <LeadsFilter
          lead_filter={lead_filter}
          setlead_filter={setlead_filter}
          getSearchedLeads={getSearchedLeads}
        />
      ) : (
        ""
      )}
      {campaign_model ? (
        <CampaignModel
          campaign_model={campaign_model}
          setcampaign_model={setcampaign_model}
          leadsSelectedIds={leadsSelectedIds}
          setLeadsSelectedIds={setLeadsSelectedIds}
          getAllLeads={getFilteredLeads}
        />
      ) : (
        ""
      )}
      {assignLabel ? (
        <AssignLabels
          assignLabel={assignLabel}
          setAssignLabel={setAssignLabel}
          leadsSelectedIds={leadsSelectedIds}
          setLeadsSelectedIds={setLeadsSelectedIds}
          getAllLeads={getFilteredLeads}
        />
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <input
            onChange={() => setCheck(1)}
            type="checkbox"
            name="is_deleted"
          />
          {"  "}
          <span className="text-danger">Delete it from server as well?</span>
        </SweetAlert>
      ) : null}
      {confirm_alert2 ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert2(false)
            setsuccess_dlg(true)
            updateFlag()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert2(false)}
        >
          <span className="text-danger">
            Do you want to update star on selected leads?
          </span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">
              Dead Leads
              {total !== "" ? <span> ({total})</span> : ""}
            </h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              <div className="btn-group me-2 mb-2">
                <button type="button" className="btn btn-primary">
                  Sort by
                </button>
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-caret-down"></i>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => getSearchedLeads("name,asc", "sort")}
                    >
                      A to Z
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => getSearchedLeads("name,desc", "sort")}
                    >
                      Z to A
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => getSearchedLeads("id,desc", "sort")}
                    >
                      Latest Added
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        getSearchedLeads("updated_at,desc", "sort")
                      }
                    >
                      Latest Updated
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        getSearchedLeads("followup_counter,desc", "sort")
                      }
                    >
                      Most Followups
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        getSearchedLeads("followup_counter,asc", "sort")
                      }
                    >
                      Least Followups
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        getSearchedLeads("flag_date_time,ASC", "sort")
                      }
                    >
                      Sort by Star
                    </span>
                  </li>
                  {localStorage.getItem("ShowImportant") ? (
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          getSearchedLeads("important_date,ASC", "sort")
                        }
                      >
                        Sort by Important Date
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              {blur ? (
                <div className="btn-group me-2 mb-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    {getPermission() &&
                    getPermission().lead.lead.delete.is_checked === "yes" ? (
                      <li>
                        <span
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => setconfirm_alert(true)}
                        >
                          Batch delete
                        </span>
                      </li>
                    ) : (
                      <li>
                        <span
                          className="dropdown-item disabled"
                          onClick={() => setconfirm_alert(true)}
                        >
                          Batch delete
                        </span>
                      </li>
                    )}
                    {getPermission() &&
                    getPermission().lead_transfer.lead_transfer.view
                      .is_checked === "yes" ? (
                      getCurrentUser().role_name == "Executive" ? (
                        getPermission() &&
                        getPermission().transfer_request.transfer_request.view
                          .is_checked === "yes" ? (
                          <li>
                            <span
                              className="dropdown-item"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModal4(true)
                              }}
                            >
                              Change Leadholder
                            </span>
                          </li>
                        ) : (
                          <li>
                            <span
                              className="dropdown-item disabled"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModal4(true)
                              }}
                            >
                              Change Leadholder
                            </span>
                          </li>
                        )
                      ) : (
                        <li>
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModal2(true)
                            }}
                          >
                            Change Leadholder
                          </span>
                        </li>
                      )
                    ) : (
                      <li>
                        <span
                          className="dropdown-item disabled"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModal2(true)
                          }}
                        >
                          Change Leadholder
                        </span>
                      </li>
                    )}
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert2(true)
                        }}
                      >
                        Update Star
                      </span>
                    </li>
                    {getCurrentUser() &&
                    getCurrentUser().role_name !== "Executive" ? (
                      <>
                        <li>
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setAssignLabel(true)
                            }}
                          >
                            Assign Labels
                          </span>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => setcampaign_model(true)}
                      >
                        Send Whatsapp
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="btn-group me-2 mb-2">
                  <button type="button" className="btn btn-primary" disabled>
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    disabled
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu"></ul>
                </div>
              )}
              {getCurrentUser() &&
              getCurrentUser().role_name !== "Executive" ? (
                <button
                  className="btn btn-primary submit__button me-2 mb-2"
                  onClick={() => {
                    getSearchedLeads(getCurrentUser().id, "leadholder")
                  }}
                >
                  My Leads
                </button>
              ) : (
                ""
              )}
              {getPermission() &&
              getPermission().lead_export.lead_export.view.is_checked ===
                "yes" ? (
                <div className="btn-group me-2 mb-2">
                  <button type="button" className="btn btn-primary">
                    Export Leads
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => exportLead("All")}
                      >
                        All
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => exportLead("Filtered")}
                      >
                        Filtered
                      </span>
                    </li>
                    {blur ? (
                      <li>
                        <span
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => exportLead("Selected")}
                        >
                          Selected
                        </span>
                      </li>
                    ) : (
                      <li>
                        <span
                          className="dropdown-item disabled"
                          style={{ cursor: "pointer" }}
                          onClick={() => exportLead("Selected")}
                        >
                          Selected
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              ) : (
                ""
              )}
              <button
                className="btn btn-primary submit__button me-2 mb-2"
                onClick={() => {
                  setlead_filter(true)
                }}
              >
                <i className="fa fa-filter me-2"></i>
                Filter
              </button>
              {/* <button
								className="btn btn-primary submit__button me-2 mb-2"
								onClick={() => {
									setModal3(true)
								}}
							>
                        <i className="fa fa-filter me-2"></i>
								Filter
							</button> */}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Followup
                <span> ({searchedCount})</span>
                <button
                  className="btn btn-success ms-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchedCount(""), getDeadLeads(), clearLocal()
                  }}
                >
                  <i className="fa me-2" aria-hidden="true">
                    &#xf021;
                  </i>
                  Reset Filters
                </button>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <Modal isOpen={modal2} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            transferLeads(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Change Leadholder
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => setModal2(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {/* <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Users"
                        type="select"
                        name="user_id"
                     >
                        <option value="" hidden>
                           Select User
                        </option>
                        <option value="0">Company Pool</option>
                        {JSON.parse(localStorage.getItem("AllUsers")) &&
                           JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                              <option value={item.id} key={i}>
                                 {item.name}
                              </option>
                           ))}
                     </AvField>
                  </div> */}
            <div className="form-group mb-3">
              <label>Select User To Assign</label>
              <Select
                isMulti
                isClearable="true"
                name="assignees"
                options={options}
                onChange={e => getSelectedId(e)}
              />
            </div>
            <div className="d-flex">
              <div className="mb-3 me-2">
                <AvField
                  label="Transfer as New"
                  type="checkbox"
                  name="transfer_as_new"
                  onClick={e => getCheckValue(e.target)}
                />
              </div>
              {transferNew == "1" ? (
                <div className="mb-3 ms-4">
                  <AvField
                    label="Hide Lead History"
                    type="checkbox"
                    name="hide_lead_history"
                    onClick={e => getCheckValue2(e.target)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <label>Select Option:</label>
              <AvRadioGroup inline name="type">
                <AvRadio label="Today's Priority" value="priority" />
                <AvRadio label="Tomorrow's Followup" value="followup" />
              </AvRadioGroup>
            </div>
            <div className="modal-footer">
              <div className="text-center">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => setModal2(false)}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
      <Modal isOpen={modal4} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            transferRequest(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Change Leadholder Request
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => setModal4(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <AvField
                className="select-arrow"
                label="Users"
                type="select"
                name="user_id"
              >
                <option value="" hidden>
                  Select User
                </option>
                <option value="0">Company Pool</option>
                {JSON.parse(localStorage.getItem("All-User")) &&
                  JSON.parse(localStorage.getItem("All-User")).map((item, i) =>
                    getCurrentUser()?.id !== item.id ? (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    ) : (
                      ""
                    )
                  )}
              </AvField>
            </div>
            <div className="modal-footer">
              <div className="text-center">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={() => setModal4(false)}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-striped table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  {/* <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      ID
                      {id_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setid_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadID") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort5 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort5(true),
                                setSort2(false),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort6 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(false),
                                setSort5(true),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="first-filter">
                        <InputFilter
                          id_filter={id_filter}
                          setid_filter={setid_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </div>
                  </th> */}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Name
                      {name_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {/*{sort ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort(true),
                                                setSort2(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort2 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(false),
                                                setSort(true),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )} */}
                      <InputFilter
                        name_filter={name_filter}
                        setname_filter={setname_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Contact
                      {contact_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setcontact_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadContact") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setcontact_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setcontact_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        contact_filter={contact_filter}
                        setcontact_filter={setcontact_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Lead Source
                      {leadsource_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setleadsource_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadSource") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setleadsource_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setleadsource_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        leadsource_filter={leadsource_filter}
                        setleadsource_filter={setleadsource_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  {getCurrentUser() && getCurrentUser().tag == 1 ? (
                    <th>
                      <div
                        className="position-relative"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Tag
                        {tag_filter ? (
                          <>
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => settag_filter(false)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          </>
                        ) : (
                          <>
                            {localStorage.getItem("leadTag") == "" ? (
                              <span
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={() => settag_filter(true)}
                              >
                                <i className="fa fa-filter ms-2"></i>
                              </span>
                            ) : (
                              <span
                                className=""
                                style={{ cursor: "pointer", color: "orange" }}
                                onClick={() => settag_filter(true)}
                              >
                                <i className="fa fa-filter ms-2"></i>
                              </span>
                            )}
                          </>
                        )}
                        <MultipleChoice
                          tag_filter={tag_filter}
                          settag_filter={settag_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </th>
                  ) : (
                    ""
                  )}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Label
                      {label_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setlabel_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadLabel") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setlabel_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setlabel_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        label_filter={label_filter}
                        setlabel_filter={setlabel_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  {getCurrentUser().role_name == "Executive" ? (
                    ""
                  ) : (
                    <th>
                      <div
                        className="position-relative"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Leadholder
                        {leadholder_filter ? (
                          <>
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setleadholder_filter(false)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          </>
                        ) : (
                          <>
                            {localStorage.getItem("leadHolder") == "" ? (
                              <span
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={() => setleadholder_filter(true)}
                              >
                                <i className="fa fa-filter ms-2"></i>
                              </span>
                            ) : (
                              <span
                                className=""
                                style={{ cursor: "pointer", color: "orange" }}
                                onClick={() => setleadholder_filter(true)}
                              >
                                <i className="fa fa-filter ms-2"></i>
                              </span>
                            )}
                          </>
                        )}
                        <MultipleChoice
                          leadholder_filter={leadholder_filter}
                          setleadholder_filter={setleadholder_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </th>
                  )}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Status
                      {dead_filter ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setdead_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("deadStatus") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setdead_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setdead_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        dead_filter={dead_filter}
                        setdead_filter={setdead_filter}
                        data={allDead}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Last Message
                      {message_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setmessage_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadMessage") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setmessage_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setmessage_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        message_filter={message_filter}
                        setmessage_filter={setmessage_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
                {followup &&
                  followup.map((item, obj) => (
                    <DeadList
                      data={item}
                      i={obj}
                      key={obj}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      getDeadLeads={getFilteredLeads}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getSearchedLeads}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default DeadLeads
