import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal, Spinner, Col } from "reactstrap"
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { MentionsInput, Mention } from 'react-mentions'
import _escapeRegExp from 'lodash/escapeRegExp'
import Select from "react-select"
import Messages from "./messages"

let selectedAction = []
let flows = []

export default function WorkFlowModal(props) {
   const [loader, setLoader] = useState(false)
   const [loading, setLoading] = useState(false)
   const [eventName, setEventName] = useState(props.flow?.event ? props.flow.event : "")
   const [allData, setAllData] = useState([])
   const [whatsappNumber, setWhatsappNumber] = useState("primary")
   const [whatsappUsers, setWhatsappUsers] = useState([])
   const [emailUsers, setEmailUsers] = useState([])
   const [leadholder, setLeadholder] = useState("")
   const [leadStatus, setLeadStatus] = useState("")
   const [followupDays, setFollowupDays] = useState(0)
   const [followupHours, setFollowupHours] = useState(0)
   const [followupMinutes, setFollowupMinutes] = useState(0)
   const [orders, setOrders] = useState()
   const [repeatOrder, setRepeatOrder] = useState()
   const [noUpdationDays, setNoUpdationDays] = useState()
   const [reminder, setReminder] = useState("")
   const [reminderDays, setReminderDays] = useState()
   const [selectedActions, setSelectedActions] = useState([]);
   const [selectedLabels, setSelectedLabels] = useState([]);
   const [state, setState] = useState({
      name: 'React',
      value: props.flow?.message ? props.flow?.message : "",
      singleLineValue: props.flow?.message ? props.flow?.message : "",
      mentionData: null,
      variables: [
         {
            _id: '{company_name}',
            name: "{company_name}"
         },
         {
            _id: '{user_name}',
            name: "{user_name}"
         },
         {
            _id: '{user_number}',
            name: "{user_number}"
         },
         {
            _id: '{order_id}',
            name: "{order_id}"
         },
         {
            _id: '{proforma_id}',
            name: "{proforma_id}"
         },
         {
            _id: '{lead_name}',
            name: "{lead_name}"
         },
         {
            _id: '{customer_care_number}',
            name: "{customer_care_number}"
         },
         {
            _id: '{brand_name}',
            name: "{brand_name}"
         },
         {
            _id: '{order_balance}',
            name: "{order_balance}"
         },
         {
            _id: '{due_date}',
            name: "{due_date}"
         }
      ]
   });
   const [state2, setState2] = useState({
      name: 'React',
      value: props.flow?.message ? props.flow?.message : "",
      singleLineValue: props.flow?.message ? props.flow?.message : "",
      mentionData: null,
      variables: [
         {
            _id: '{company_name}',
            name: "{company_name}"
         },
         {
            _id: '{user_name}',
            name: "{user_name}"
         },
         {
            _id: '{user_number}',
            name: "{user_number}"
         },
         {
            _id: '{order_id}',
            name: "{order_id}"
         },
         {
            _id: '{proforma_id}',
            name: "{proforma_id}"
         },
         {
            _id: '{lead_name}',
            name: "{lead_name}"
         },
         {
            _id: '{customer_care_number}',
            name: "{customer_care_number}"
         },
         {
            _id: '{brand_name}',
            name: "{brand_name}"
         },
         {
            _id: '{order_balance}',
            name: "{order_balance}"
         },
         {
            _id: '{due_date}',
            name: "{due_date}"
         }
      ]
   });
   const [stages, setStages] = useState([]);

   const getAllDrive = () => {
      setAllData([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/klikdrive?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllData(res.data.data.data)
            } else {
            }
         })
   }

   const handleChangeSingle = (e, newValue) => {
      setState({
         ...state,
         singleLineValue: newValue
      });
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "send_whatsapp") {
            flows[i]["message"] = swapTags(newValue);
         }
      }
   };

   const handleChangeSingle2 = (e, newValue) => {
      setState2({
         ...state2,
         singleLineValue: newValue
      });
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "send_email") {
            flows[i]["message"] = swapTags(newValue);
         }
      }
   };

   const variableData1 = state.variables.map((variable) => ({
      id: variable._id,
      display: `${variable.name}`
   }));

   const variableData2 = allData?.map((link) => ({
      id: link?.link,
      display: `${link?.title}`
   }));

   const variableData = [...variableData1, ...variableData2];

   function swapTags(text) {
      let displayText = text;
      const tagsWithBraces = text.match(/@\[\{[^\]]+\}\]\([^\)]+\)/gi) || [];
      const tagsWithoutBraces = text.match(/@\[[^\]]+\]\([^\)]+\)/gi) || [];

      // Handle tags with curly braces
      tagsWithBraces.forEach(tag => {
         const tagData = tag.slice(3, -1); // Extract data between [{...}]({...})
         const tagDisplayValue = tagData.split('](')[0]; // Get the content before ']('
         const tagReplacement = '{' + tagDisplayValue; // Ensure { and } are retained
         displayText = displayText.replace(new RegExp(_escapeRegExp(tag), 'gi'), tagReplacement);
      });

      // Handle tags without curly braces
      tagsWithoutBraces.forEach(tag => {
         const tagData = tag.slice(2, -1); // Extract data between [...](...)
         const tagDisplayValue = tagData.split('](')[0]; // Get the content before ']('
         const tagReplacement = tagDisplayValue + ": " + tagData.split('](')[1]; // No need to add curly braces
         displayText = displayText.replace(new RegExp(_escapeRegExp(tag), 'gi'), tagReplacement);
      });

      return displayText;
   }

   const getEventName = (e) => {
      setEventName(e)
      setSelectedActions([])
      setRepeatOrder()
      setOrders()
      selectedAction = []
      flows = []
      setStages([])
      setWhatsappNumber("primary")
      setWhatsappUsers({
         "lead": true,
         "user": false
      })
   }

   const labelOptions = []

   if (JSON.parse(localStorage.getItem("AllLabels"))) {
      JSON.parse(localStorage.getItem("AllLabels")).map(item => {
         labelOptions.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const userOptions = []

   if (JSON.parse(localStorage.getItem("All-User"))) {
      userOptions.push({
         value: "0",
         label: "Company Pool",
      })
      JSON.parse(localStorage.getItem("All-User")).map(item => {
         userOptions.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const options = []

   if (eventName === "lead_matured" || eventName === "repeat_order") {
      options.push({ value: "add_label", label: "Add label", })
      options.push({ value: "assign_lead_to", label: "Assign lead to", })
      options.push({ value: "send_whatsapp", label: "Send Whatsapp", })
      options.push({ value: "send_email", label: "Send Email", })
   } else if (eventName === "lead_create") {
      options.push({ value: "add_label", label: "Add label", })
      options.push({ value: "send_whatsapp", label: "Send Whatsapp", })
      options.push({ value: "send_email", label: "Send Email", })
   } else if (eventName === "no_updation_since" || eventName === "no_order_since") {
      options.push({ value: "add_label", label: "Add label", })
      options.push({ value: "assign_lead_to", label: "Assign lead to", })
      options.push({ value: "lead_status", label: "Change lead status", })
      options.push({ value: "send_whatsapp", label: "Send Whatsapp", })
      options.push({ value: "send_email", label: "Send Email", })
   } else if (eventName === "followup_stages") {
      JSON.parse(localStorage.getItem("AllFollowups")) &&
         JSON.parse(localStorage.getItem("AllFollowups")).map((item, i) => (
            options.push({ value: item.id, label: item.name })
         ))
   } else if (eventName === "mature_stages") {
      JSON.parse(localStorage.getItem("MatureStages")) &&
         JSON.parse(localStorage.getItem("MatureStages")).map((item, i) => (
            options.push({ value: item.id, label: item.name })
         ))
   } else if (eventName === "lead_fetched") {
      options.push({ value: "add_label", label: "Add label", })
      options.push({ value: "followup_time", label: "Set Followup Time", })
      options.push({ value: "assign_lead_to", label: "Assign lead to", })
      options.push({ value: "send_whatsapp", label: "Send Whatsapp", })
      options.push({ value: "send_email", label: "Send Email", })
   } else if (eventName === "") {

   } else {
      options.push({ value: "send_whatsapp", label: "Send Whatsapp", })
      options.push({ value: "send_email", label: "Send Email", })
   }

   const getSelectedAction = selectedItem => {
      selectedAction = []
      selectedItem && selectedItem.map(item => selectedAction.push(item.value))
      if (eventName === "followup_stages" || eventName === "mature_stages") {
         const updatedStages = [...stages]; // Create a copy of the current state
         selectedItem?.forEach(item => {
            if (!updatedStages.some(flow => flow.id === item.value)) {
               updatedStages.push({ id: item.value, name: item.label, action: `${eventName === "mature_stages" ? "send_mature_status_messages" : "send_followup_status_messages"}`, messages: [{ hours: "", message: "" }] });
            }
         });
         setStages(updatedStages.filter(flow => selectedItem?.some(item => item.value === flow.id)));
      } else {
         selectedItem?.forEach(item => {
            if (!flows.some(flow => flow.action === item.value)) {
               if (eventName === "repeat_order" && repeatOrder) {
                  flows.push({ action: item.value, repeat_orders: repeatOrder });
               } else if (eventName === "brand_missed" && orders) {
                  flows.push({ action: item.value, orders: orders });
               } else if (eventName === "no_updation_since" || eventName === "no_order_since" && noUpdationDays) {
                  flows.push({ action: item.value, days: noUpdationDays });
               } else if (eventName === "balance_payment" || eventName === "product/service_renewal" && reminderDays) {
                  flows.push({ action: item.value, days: reminderDays, when: reminder });
               } else {
                  flows.push({ action: item.value });
               }
            }
         });
         flows = flows.filter(flow => selectedItem?.some(item => item.value === flow.action));
      }
   }

   const getLabels = selectedLabel => {
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "add_label") {
            flows[i]["labels"] = selectedLabel;
         }
      }
   }

   const getLeadholder = (selectedUsers) => {
      const updatedArray = selectedUsers.map(obj => ({
         ...obj,
         pointer: 0 // Adding a new property to each object
      }));
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "assign_lead_to") {
            flows[i]["transfer_to"] = updatedArray;
         }
      }
   }

   const getLeadStatus = (e) => {
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "lead_status") {
            flows[i]["status"] = e;
         }
      }
   }

   const getFollowupTime = (e) => {
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "followup_time") {
            flows[i][e.target.name] = e.target.value;
         }
      }
   }

   const getRepeatOrders = (e) => {
      for (let i = 0; i < flows.length; i++) {
         flows[i]["repeat_orders"] = e;
      }
      setRepeatOrder(e)
   }

   const getOrders = (e) => {
      for (let i = 0; i < flows.length; i++) {
         flows[i]["orders"] = e;
      }
      setOrders(e)
   }

   const getNoUpdationDays = (e) => {
      for (let i = 0; i < flows.length; i++) {
         flows[i]["days"] = e;
      }
      setNoUpdationDays(e)
   }

   const getReminder = (e) => {
      for (let i = 0; i < flows.length; i++) {
         flows[i]["when"] = e;
         if (e === "same_day") {
            flows[i]["days"] = 0;
            setReminderDays(0)
         }
      }
      setReminder(e)
   }

   const getReminderDays = (e) => {
      for (let i = 0; i < flows.length; i++) {
         flows[i]["days"] = e;
      }
      setReminderDays(e)
   }

   const getWhatsappUser = (e, f) => {
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "send_whatsapp") {
            flows[i][f] = e;
         }
      }
      setWhatsappUsers({
         ...whatsappUsers,
         [f]: e
      })
   }

   const getWhatsappNumber = (e) => {
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "send_whatsapp") {
            flows[i]["number"] = e;
            if (e === "leadholder") {
               flows[i]["lead"] = true;
               flows[i]["user"] = false;
            }
         }
      }
      if (e === "leadholder") {
         setWhatsappUsers({
            "lead": true,
            "user": false
         })
      }
      setWhatsappNumber(e)
   }

   const getEmailUser = (e, f) => {
      for (let i = 0; i < flows.length; i++) {
         if (flows[i].action === "send_email") {
            flows[i][f] = e;
         }
      }
      setEmailUsers({
         ...emailUsers,
         [f]: e
      })
   }

   const addFields = (id) => {
      setStages(prevStages => {
         return prevStages.map(stage => {
            if (stage.id === id) {
               return {
                  ...stage,
                  messages: [...stage.messages, { hours: "", message: "" }]
               };
            }
            return stage;
         });
      });
   }

   const handleFields = (index, e, id) => {
      setStages(prevStages => {
         return prevStages.map(stage => {
            if (stage.id === id) {
               return {
                  ...stage,
                  messages: stage.messages.map((message, i) => {
                     if (i === index) {
                        return {
                           ...message,
                           [e.target.name ? e.target.name : "message"]: e.target.name ? e.target.value : swapTags(e.target.value)
                        };
                     }
                     return message;
                  })
               };
            }
            return stage;
         });
      });
   }

   const removeFields = (index, id) => {
      setLoader(true)
      setStages(prevStages => {
         return prevStages.map(stage => {
            if (stage.id === id) {
               return {
                  ...stage,
                  messages: stage.messages.filter((message, i) => i !== index)
               };
            }
            return stage;
         });
      });
      setTimeout(() => {
         setLoader(false)
      }, 500);
   }

   const getStageUser = (e, f, id) => {
      setStages(prevStages => {
         return prevStages.map(stage => {
            if (stage.id === id) {
               return {
                  ...stage,
                  [f]: e
               };
            }
            return stage;
         });
      });
   }

   const submitMessage = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.flow !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/workflow/${props.flow.id}`,
               {
                  title: value.title,
                  event: value.events,
                  data: eventName === "followup_stages" || eventName === "mature_stages" ? JSON.stringify(stages) : JSON.stringify(flows),
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.setIsOpen(false)
                  props.setmodal_workflow(false)
                  props.setFlow(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/workflow`,
               {
                  title: value.title,
                  event: value.events,
                  data: eventName === "followup_stages" || eventName === "mature_stages" ? JSON.stringify(stages) : JSON.stringify(flows),
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getAllFlows()
                  props.setmodal_workflow(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      getAllDrive()
      setSelectedActions([])
      if (props.flow !== undefined) {
         selectedAction = []
         if (props.flow?.event === "followup_stages" || props.flow?.event === "mature_stages") {
            setStages(props.flow?.data)
         } else {
            flows = props.flow?.data
         }
         if (props.flow?.event === "repeat_order") {
            const orders = props.flow?.data[0].repeat_orders
            setRepeatOrder(orders)
         } else if (props.flow?.event === "no_updation_since" || props.flow?.event === "no_order_since") {
            const days = props.flow?.data[0].days
            setNoUpdationDays(days)
         } else if (props.flow?.event === "balance_payment" || props.flow?.event === "product/service_renewal") {
            const days = props.flow?.data[0].days
            setReminderDays(days)
            const when = props.flow?.data[0].when
            setReminder(when)
         } else if (props.flow?.event === "brand_missed") {
            const order = props.flow?.data[0].orders
            setOrders(order)
         }
         const updatedActions = props.flow?.data?.map((item) => {
            if (props.flow?.event === "followup_stages" || props.flow?.event === "mature_stages") {
               return { value: item.id, label: item.name };
            } else {
               selectedAction.push(item?.action);
               if (item.action === "send_whatsapp") {
                  setWhatsappUsers(item);
                  setWhatsappNumber(item?.number);
                  setState({
                     ...state,
                     singleLineValue: item?.message,
                  });
                  return { value: "send_whatsapp", label: "Send Whatsapp" };
               } else if (item.action === "send_email") {
                  setEmailUsers(item);
                  setState2({
                     ...state2,
                     singleLineValue: item?.message,
                  });
                  return { value: "send_email", label: "Send Email" };
               } else if (item.action === "assign_lead_to") {
                  setLeadholder(item?.transfer_to);
                  return { value: "assign_lead_to", label: "Assign lead to" };
               } else if (item.action === "followup_time") {
                  setFollowupDays(item?.days);
                  setFollowupHours(item?.hours);
                  setFollowupMinutes(item?.minutes);
                  return { value: "followup_time", label: "Set Followup Time" };
               } else if (item.action === "add_label") {
                  setSelectedLabels(item?.labels);
                  return { value: "add_label", label: "Add label" };
               } else if (item.action === "lead_status") {
                  setLeadStatus(item?.status);
                  return { value: "lead_status", label: "Change lead status" };
               }
            }

            return null; // or handle the default case if needed
         });

         // Filter out null values (default case) and update the state
         const defaultActions = updatedActions.filter((action) => action !== null);
         setSelectedActions([...selectedActions, ...defaultActions]);
      } else {
         selectedAction = []
         flows = []
      }
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_workflow(false)
            if (props.setIsOpen) {
               props.setIsOpen(false)
            }
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         <Modal isOpen={props.modal_workflow} centered={true} size="lg">
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitMessage(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.flow !== undefined
                        ? "Edit Workflow"
                        : "Create Workflow"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => { props.setmodal_workflow(false), props.setIsOpen ? props.setIsOpen(false) : "" }}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body row">
                  <div className="mb-3 required col-md-6">
                     <AvField
                        label="Title"
                        placeholder="Enter subject"
                        type="text"
                        name="title"
                        defaultValue={props.flow && props.flow.title}
                     />
                  </div>
                  <div className="mb-3 required col-md-6">
                     <AvField
                        className="select-arrow"
                        label="Select Event"
                        type="select"
                        name="events"
                        value={eventName}
                        onChange={(e) => getEventName(e.target.value)}
                     >
                        <option value="">Select</option>
                        <option value="lead_create">Lead Create</option>
                        <option value="lead_fetched">Lead Fetched</option>
                        <option value="lead_matured">Lead Matured</option>
                        <option value="new_proforma">New Proforma</option>
                        <option value="new_order">New Order</option>
                        <option value="repeat_order">Repeat Orders</option>
                        <option value="followup_stages">Followup stages</option>
                        <option value="mature_stages">Mature stages</option>
                        <option value="brand_missed">Brand Missed</option>
                        <option value="update_proforma">Update Proforma</option>
                        <option value="update_order">Update Order</option>
                        <option value="no_updation_since">No updation since</option>
                        <option value="no_order_since">No order since</option>
                        <option value="balance_payment">Balance Payment</option>
                        <option value="product/service_renewal">Product/Service Renewal</option>
                        <option value="birthday">Birthday</option>
                        <option value="anniversary">Anniversary</option>
                     </AvField>
                  </div>
                  {eventName === "repeat_order" ?
                     <div className="mb-3 required">
                        <AvField
                           className="select-arrow"
                           label="Set number of orders after which you want to take action"
                           type="number"
                           name="repeat_orders"
                           placeholder="Enter orders"
                           onChange={(e) => getRepeatOrders(e.target.value)}
                           required
                           defaultValue={repeatOrder}
                        />
                     </div>
                     :
                     ""
                  }
                  {eventName === "brand_missed" ?
                     <div className="mb-3 required">
                        <AvField
                           className="select-arrow"
                           label="Set number of orders after which you want to take action"
                           type="number"
                           name="orders"
                           placeholder="Enter orders"
                           onChange={(e) => getOrders(e.target.value)}
                           required
                           defaultValue={orders}
                        />
                     </div>
                     :
                     ""
                  }
                  {eventName === "no_updation_since" || eventName === "no_order_since" ?
                     <div className="mb-3 required">
                        <AvField
                           className="select-arrow"
                           label="Set number of days after which you want to take action"
                           type="number"
                           name="no_updation_since"
                           placeholder="Enter days"
                           onChange={(e) => getNoUpdationDays(e.target.value)}
                           required
                           defaultValue={noUpdationDays}
                        />
                     </div>
                     :
                     ""
                  }
                  {eventName === "balance_payment" || eventName === "product/service_renewal" ?
                     <>
                        <div className={` mb-3 required ${reminder === "same_day" || reminder === "" ? "col-md-12" : "col-md-6"}`}>
                           <AvField
                              className="select-arrow"
                              label="Set when you want to take action"
                              type="select"
                              name="reminder"
                              onChange={(e) => getReminder(e.target.value)}
                              required
                              defaultValue={reminder}
                           >
                              <option value="">Select</option>
                              <option value="same_day">Same day</option>
                              <option value="earlier">Earlier</option>
                              <option value="after">After</option>
                           </AvField>
                        </div>
                        {reminder === "same_day" || reminder === "" ?
                           ""
                           :
                           <div className="mb-3 required col-md-6">
                              <label>Enter number of days</label>
                              <div className="input-group">
                                 <span
                                    className="input-group-text"
                                    id="basic-addon2"
                                 >
                                    {reminder === "earlier" ? "-" : "+"}
                                 </span>
                                 <input
                                    aria-describedby="basic-addon2"
                                    // label="Enter number of days"
                                    type="number"
                                    className="form-control"
                                    name="payment_reminder"
                                    placeholder="Enter days"
                                    onChange={(e) => getReminderDays(e.target.value)}
                                    required
                                    defaultValue={reminderDays}
                                    validate={{
                                       maxLength: { value: 2, errorMessage: 'Please enter value between 1 to 30' },
                                    }}
                                 />
                              </div>
                              <small>Please enter value between 1 to 30</small>
                           </div>
                        }
                     </>
                     :
                     ""
                  }
                  {eventName ?
                     <div className="mb-3 required">
                        <label>Select Action</label>
                        <Select
                           isMulti
                           isClearable="true"
                           name="actions"
                           options={options}
                           value={selectedActions}
                           onChange={(selectedOption) => { setSelectedActions(selectedOption), getSelectedAction(selectedOption) }}
                        />
                     </div>
                     :
                     ""
                  }
                  {eventName === "followup_stages" || eventName === "mature_stages" && stages.length > 0 ?
                     stages?.map((item, i) => (
                        <>
                           <div className="d-flex" >
                              <h5>{item?.name}</h5>
                              <div className="button-section ms-3">
                                 <button
                                    className="btn btn-success p-1"
                                    type="button"
                                    onClick={() => addFields(item.id)}
                                 >
                                    <i className="fa fa-plus p-1"></i>
                                 </button>
                              </div>
                           </div>
                           <div className="mb-3">
                              <label>Select whom to send Message</label>
                              <AvCheckboxGroup
                                 inline
                                 name="stage_users"
                                 onChange={(e) => getStageUser(e.target.checked, e.target.value, item.id)}
                                 required
                              >
                                 <AvCheckbox label="Lead" value="lead" checked={item?.lead} />
                                 <AvCheckbox label="User" value="user" checked={item?.user} />
                              </AvCheckboxGroup>
                           </div>
                           <div className="row">
                              <div className="col-lg-7">
                                 <label>Message</label>
                              </div>
                              <div className="col-lg-5">
                                 <label>Enter No. of hours after which you want to send message</label>
                              </div>
                           </div>
                           {loader ?
                              <Spinner />
                              :
                              item?.messages?.map((option, index) =>
                                 <Messages
                                    id={item?.id}
                                    i={i}
                                    index={index}
                                    data={option}
                                    handleFields={handleFields}
                                    removeFields={removeFields}
                                    variableData={variableData}
                                 />
                              )}
                        </>
                     ))
                     :
                     ""
                  }
                  {selectedAction.includes("add_label") ?
                     <>
                        <div className="mb-3 required">
                           <label>Select Lead Labels</label>
                           <Select
                              isMulti
                              isClearable="true"
                              name="lead_label_id"
                              options={labelOptions}
                              value={selectedLabels}
                              onChange={e => { setSelectedLabels(e), getLabels(e) }}
                              required
                           />
                        </div>
                     </>
                     :
                     ""
                  }
                  {selectedAction.includes("assign_lead_to") ?
                     <>
                        <div className="mb-3 required">
                           <label>Select Users to transfer lead</label>
                           <Select
                              isMulti
                              isClearable="true"
                              name="user_id"
                              options={userOptions}
                              value={leadholder}
                              onChange={e => { setLeadholder(e), getLeadholder(e) }}
                              required
                           />
                           {/* <AvField
                              className="select-arrow"
                              label="Select User to transfer lead"
                              type="select"
                              name="user_id"
                              onChange={(e) => getLeadholder(e.target.value)}
                              required
                              defaultValue={leadholder}
                           >
                              <option value="" hidden>
                                 Select User
                              </option>
                              <option value="0">Company Pool</option>
                              {JSON.parse(localStorage.getItem("All-User")) &&
                                 JSON.parse(localStorage.getItem("All-User")).map((item, i) => (
                                    getCurrentUser()?.id !== item.id ?
                                       <option value={item.id} key={i}>
                                          {item.name}
                                       </option>
                                       :
                                       ""
                                 ))}
                           </AvField> */}
                        </div>
                     </>
                     :
                     ""
                  }
                  {selectedAction.includes("lead_status") ?
                     <>
                        <div className="mb-3 required">
                           <AvField
                              className="select-arrow"
                              label="Select lead Status"
                              type="select"
                              name="lead_status"
                              onChange={(e) => getLeadStatus(e.target.value)}
                              required
                              defaultValue={leadStatus}
                           >
                              <option value="">Select</option>
                              <option value="new">Untouched</option>
                              <option value="dead">Dead</option>
                           </AvField>
                        </div>
                     </>
                     :
                     ""
                  }
                  {selectedAction.includes("followup_time") ?
                     <div className="d-flex justify-content-between">
                        <div className="mb-3 required">
                           <AvField
                              className="select-arrow"
                              label="Set days"
                              type="number"
                              name="days"
                              placeholder="Enter days"
                              onChange={(e) => getFollowupTime(e)}
                              required
                              defaultValue={followupDays}
                              max={99}
                              validate={{
                                 maxLength: { value: 2, errorMessage: 'Please enter value between 0-99' }
                              }}
                           >
                           </AvField>
                        </div>
                        <div className="mb-3 required">
                           <AvField
                              className="select-arrow"
                              label="Set Hours"
                              type="number"
                              name="hours"
                              placeholder="Enter hours"
                              onChange={(e) => getFollowupTime(e)}
                              required
                              defaultValue={followupHours}
                              validate={{
                                 max: { value: 23, errorMessage: 'Please enter value between 0-23' },
                                 maxLength: { value: 2, errorMessage: 'Please enter value between 0-23' }
                              }}
                           >
                           </AvField>
                        </div>
                        <div className="mb-3 required">
                           <AvField
                              className="select-arrow"
                              label="Set Minutes"
                              type="number"
                              name="minutes"
                              placeholder="Enter minutes"
                              onChange={(e) => getFollowupTime(e)}
                              required
                              defaultValue={followupMinutes}
                              validate={{
                                 max: { value: 59, errorMessage: 'Please enter value between 0-59' },
                                 maxLength: { value: 2, errorMessage: 'Please enter value between 0-59' }
                              }}
                           >
                           </AvField>
                        </div>
                     </div>
                     :
                     ""
                  }
                  {selectedAction.includes("send_whatsapp") ?
                     <>
                        <div className="mb-3 col-md-6">
                           <label>From which number you want to send message</label>
                           <AvRadioGroup
                              inline
                              name="whatsapp_number"
                              onChange={(e) => getWhatsappNumber(e.target.value)}
                              required
                              defaultValue={whatsappNumber}
                           >
                              <AvRadio label="Primary Number" value="primary" />
                              <AvRadio label="Leadholder's Number" value="leadholder" />
                           </AvRadioGroup>
                        </div>
                        {whatsappNumber === "primary" ?
                           <div className="mb-3 col-md-6">
                              <label>Select whom to send whatsapp</label>
                              <AvCheckboxGroup
                                 inline
                                 name="whatsapp_users"
                                 onChange={(e) => getWhatsappUser(e.target.checked, e.target.value)}
                                 required
                                 defaultValue={whatsappUsers}
                              >
                                 <AvCheckbox label="Lead" value="lead" checked={whatsappUsers?.lead} />
                                 <AvCheckbox label="User" value="user" checked={whatsappUsers?.user} />
                              </AvCheckboxGroup>
                           </div>
                           :
                           ""
                        }
                        <div className="mb-3 required">
                           <label>Whatsapp Message</label>
                           <MentionsInput
                              value={state.singleLineValue}
                              onChange={handleChangeSingle}
                              markup="@{{__type__||__id__||__display__}}"
                              placeholder="Type anything, use the @ symbol to select variables."
                              className="mentions"
                              required
                           >
                              <Mention
                                 type="user"
                                 trigger="@"
                                 data={variableData}
                                 className="mentions__mention"
                              />
                           </MentionsInput>
                           <small className="fst-italic">{`Use @ to select variables to create message.`}</small>
                        </div>
                     </>
                     :
                     ""
                  }
                  {selectedAction.includes("send_email") ?
                     <>
                        <div className="mb-3">
                           <label>Select whom to send email</label>
                           <AvCheckboxGroup
                              inline
                              name="email_users"
                              onChange={(e) => getEmailUser(e.target.checked, e.target.value)}
                              required
                              defaultValue={emailUsers}
                           >
                              <AvCheckbox label="Lead" value="lead" checked={emailUsers?.lead} />
                              <AvCheckbox label="User" value="user" checked={emailUsers?.user} />
                           </AvCheckboxGroup>
                        </div>
                        <div className="mb-3 required">
                           <label>Email Message</label>
                           <MentionsInput
                              value={state2.singleLineValue}
                              onChange={handleChangeSingle2}
                              markup="@{{__type__||__id__||__display__}}"
                              placeholder="Type anything, use the @ symbol to select variables."
                              className="mentions"
                              required
                           >
                              <Mention
                                 type="user"
                                 trigger="@"
                                 data={variableData}
                                 className="mentions__mention"
                              />
                           </MentionsInput>
                           <small className="fst-italic">{`Use @ to select variables to create message.`}</small>
                        </div>
                     </>
                     :
                     ""
                  }
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        // onClick={() => props.setmodal_workflow(false)}
                        // disabled
                        >
                           {props.flow !== undefined
                              ? "Submit"
                              : "Create"}
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
