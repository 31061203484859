import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import {
   getCurrentUser,
   getPermission,
   getFilterDate,
   setFilterDate,
} from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import TodayList from "./todayList"
import InputFilter from "../../Filter/SearchFilter"
import DateFilter from "../../Filter/DateFilter"
import MultipleChoice from "../../Filter/MultipleChoice"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import LeadsReportsFilter from "../filters"

const   TodayReport = () => {
   const [todayReport, setTodayReport] = useState([])
   const [error, setError] = useState("")
   const [loader, setLoader] = useState(<Loader />)
   const [allPages, setAllPages] = useState([])
   const [counter, setCounter] = useState("")
   const [show, setShow] = useState(false)
   const [loading, setLoading] = useState(false)
   const [lead_filter, setlead_filter] = useState(false)
   const [name_filter, setname_filter] = useState(false)
   const [leadsource_filter, setleadsource_filter] = useState(false)
   const [label_filter, setlabel_filter] = useState(false)
   const [leadholder_filter, setleadholder_filter] = useState(false)
   const [status_filter, setstatus_filter] = useState(false)
   const [action_filter, setaction_filter] = useState(false)
   const [date_filter, setdate_filter] = useState(false)
   const [message_filter, setmessage_filter] = useState(false)
   const [latestmessage_filter, setlatestmessage_filter] = useState(false)
   const [sort, setSort] = useState(false)
   const [sort2, setSort2] = useState(false)
   const [sort3, setSort3] = useState(false)
   const [sort4, setSort4] = useState(false)
   const [searchedCount, setSearchedCount] = useState("")
   const [details, setDetails] = useState("")
   const [leadsSelectedIds, setLeadsSelectedIds] = useState([])

   const getTodayReport = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/today-report?per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }&page=${localStorage.getItem("current_page")}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser()?.token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError(res.data.message)
               setCounter(res.data.data.from)
               setTodayReport(res.data.data.data)
               setAllPages(res.data.data)
               getAllDetail()
            } else {
               setLoader("")
               setError(res.data.message)
            }
         })
   }

   const paginate = e => {
      setTodayReport([])
      setAllPages([])
      setCounter("")
      setLoader(<Loader />)
      axios
         .get(
            `${e}&type=${localStorage.getItem("leadStatus") !== null
               ? localStorage.getItem("leadStatus")
               : ""
            }
            &update_by=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
            &customer=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
            &leadlabel=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : ""
            }
            &leadsource=${localStorage.getItem("leadSource") !== null
               ? localStorage.getItem("leadSource")
               : ""
            }
            &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
            &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
            &previousmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
            &message=${localStorage.getItem("latestMessage") !== null
               ? localStorage.getItem("latestMessage")
               : ""
            }
            &selectted_action=${localStorage.getItem("leadAction") !== null
               ? localStorage.getItem("leadAction")
               : ""
            }
            &followup_status_id=${localStorage.getItem("followupStatus") !== null
               ? localStorage.getItem("followupStatus")
               : ""
            }
         &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
         &dead_reason_id=${localStorage.getItem("deadReason") !== null
               ? localStorage.getItem("deadReason")
               : ""
            }
            &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
            &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setTodayReport(res.data.data.data)
               localStorage.setItem("current_page", res.data.data.current_page)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const getSearchedLeads = (e, type) => {
      setError("")
      setLoader(<Loader />)
      if (type == "name") {
         localStorage.setItem("leadName", e)
      }
      if (type == "latMsg") {
         localStorage.setItem("latestMessage", e)
      }
      if (type == "leadsource") {
         localStorage.setItem("leadSource", e)
      }
      if (type == "label") {
         localStorage.setItem("leadLabel", e)
      }
      if (type == "tag") {
         localStorage.setItem("leadTag", e)
      }
      if (type == "leadholder") {
         localStorage.setItem("leadHolder", e)
      }
      if (type == "status") {
         localStorage.setItem("leadStatus", e)
      }
      if (type == "action") {
         localStorage.setItem("leadAction", e)
      }
      if (type == "date") {
         setFilterDate(e)
      }
      if (type == "message") {
         localStorage.setItem("leadMessage", e)
      }
      if (type == "sort") {
         localStorage.setItem("leadSort", e)
      }
      setSearchedCount("")
      setTodayReport([])
      getAllDetail()
      setLoading(true)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/today-report?type=${localStorage.getItem("leadStatus") !== null
               ? localStorage.getItem("leadStatus")
               : ""
            }
         &update_by=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
         &customer=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
         &leadlabel=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : ""
            }
         &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
         &leadsource=${localStorage.getItem("leadSource") !== null
               ? localStorage.getItem("leadSource")
               : ""
            }
         &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &previousmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
         &message=${localStorage.getItem("latestMessage") !== null
               ? localStorage.getItem("latestMessage")
               : ""
            }
         &selectted_action=${localStorage.getItem("leadAction") !== null
               ? localStorage.getItem("leadAction")
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
            &followup_status_id=${localStorage.getItem("followupStatus") !== null
               ? localStorage.getItem("followupStatus")
               : ""
            }
         &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
         &dead_reason_id=${localStorage.getItem("deadReason") !== null
               ? localStorage.getItem("deadReason")
               : ""
            }
            &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCounter(res.data.data.from)
               setTodayReport(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setname_filter(false)
               setleadsource_filter(false)
               setlabel_filter(false)
               setdate_filter(false)
               setmessage_filter(false)
               setlatestmessage_filter(false)
               setleadholder_filter(false)
               setstatus_filter(false)
               setaction_filter(false)
            } else {
               setError(res.data.message)
            }
            setlead_filter(false)
            setLoading(false)
            setLoader("")
         })
   }

   const getAllDetail = () => {
      setDetails("")
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/today-report-count?type=${localStorage.getItem("leadStatus") !== null
               ? localStorage.getItem("leadStatus")
               : ""
            }
        &update_by=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
        &customer=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
        &leadlabel=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : ""
            }
        &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
        &leadsource=${localStorage.getItem("leadSource") !== null
               ? localStorage.getItem("leadSource")
               : ""
            }
        &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
        &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
        &previousmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
        &message=${localStorage.getItem("latestMessage") !== null
               ? localStorage.getItem("latestMessage")
               : ""
            }
            &followup_status_id=${localStorage.getItem("followupStatus") !== null
               ? localStorage.getItem("followupStatus")
               : ""
            }
         &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
         &dead_reason_id=${localStorage.getItem("deadReason") !== null
               ? localStorage.getItem("deadReason")
               : ""
            }
            &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
            &selectted_action=${localStorage.getItem("leadAction") !== null
               ? localStorage.getItem("leadAction")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            setDetails(res.data.data)
         })
   }

   const selectAllCheck = check => {
      if (check.checked) {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = true
               leadsSelectedIds.push(parseInt(ele[i].value))
            }
         }
      } else {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = false
               var array = leadsSelectedIds
               var index = array.indexOf(parseInt(ele[i].value))
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
      }
   }

   const exportLead = () => {
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-export`,
            {
               ids: leadsSelectedIds,
               followup_type: "today",
            },

            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               window.location.href = res.data.file_url
               setTimeout(() => {
                  dFileName(res.data.file_name)
               }, 5000)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const dFileName = e => {
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/deletefollowup-file`,
            {
               file_name: e,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
            } else {
            }
         })
   }

   useEffect(() => {
      getTodayReport()
   }, [])

   return (
      <>
         {lead_filter ? (
            <LeadsReportsFilter
               lead_filter={lead_filter}
               setlead_filter={setlead_filter}
               getSearchedLeads={getSearchedLeads}
               loading={loading}
            />
         ) : (
            ""
         )}
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={3}>
                  <h6 className="page-title">Today's Report</h6>
               </Col>
               <Col md={9}>
                  <h6 className="page-title float-end">
                     {getCurrentUser() && getCurrentUser().tag == 1 ? (
                        <>
                           {JSON.parse(localStorage.getItem("AllTags")) &&
                              JSON.parse(localStorage.getItem("AllTags")).map(item => (
                                 <button
                                    className="btn btn-primary submit__button me-2 mb-2"
                                    onClick={() => {
                                       getSearchedLeads(item.id, "tag")
                                    }}
                                 >
                                    {item.name}
                                 </button>
                              ))}
                        </>
                     ) : (
                        ""
                     )}
                     {getCurrentUser() &&
                        getCurrentUser().role_name !== "Executive" ? (
                        show ?
                           <button
                              className="btn btn-primary submit__button me-2 mb-2"
                              onClick={() => {
                                 getSearchedLeads("", "leadholder"), setShow(false)
                              }}
                           >
                              All Reports
                           </button>
                           :
                           <button
                              className="btn btn-primary submit__button me-2 mb-2"
                              onClick={() => {
                                 getSearchedLeads(getCurrentUser().id, "leadholder"), setShow(true)
                              }}
                           >
                              My Reports
                           </button>
                     ) : (
                        ""
                     )}
                     <button
                        className="btn btn-primary submit__button me-2 mb-2"
                        onClick={() => {
                           setlead_filter(true)
                        }}
                     >
                        <i className="fa fa-filter me-2"></i>
                        Filter
                     </button>
                     {getPermission() &&
                        getPermission().lead_export.lead_export.view.is_checked ===
                        "yes" ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              exportLead()
                           }}
                        >
                           Export
                        </button>
                     ) : (
                        ""
                     )}
                  </h6>
               </Col>
            </Row>
            <Row>
               <Col>
                  {searchedCount !== "" ? (
                     <h6 className="">
                        Searched Results
                        <span> ({searchedCount})</span>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
            </Row>
         </div>
         {details !== "" ? (
            <div className="fs-3 d-flex justify-content-between alert alert-warning">
               <h2>No. of Leads: {details.total_lead}</h2>
               <h2>Total Updations: {details.total_updation}</h2>
               <h2>Total Business: &#8377;{details.bussiness}</h2>
            </div>
         ) : (
            ""
         )}
         <div className="card">
            <div className="card-body">
               <div className="lead_table table table-sm table-bordered table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr className="static-header">
                           <th>
                              <input
                                 onClick={e => selectAllCheck(e.target)}
                                 className="p-0 d-inline-block"
                                 type="checkbox"
                              />
                           </th>
                           <th>#</th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Customer
                                 {name_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setname_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadName") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setname_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setname_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 {sort ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                getSearchedLeads("lead_id,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                getSearchedLeads("lead_id,asc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(834%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort(true),
                                                setSort2(false),
                                                setSort3(false),
                                                setSort4(false),
                                                getSearchedLeads("lead_id,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                getSearchedLeads("lead_id,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(834%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort2 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(false),
                                                setSort(true),
                                                setSort3(false),
                                                setSort4(false),
                                                getSearchedLeads("lead_id,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(834%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )}
                                 <div className="first-filter">
                                    <InputFilter
                                       name_filter={name_filter}
                                       setname_filter={setname_filter}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Message Updated
                                 {latestmessage_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setlatestmessage_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("latestMessage") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setlatestmessage_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setlatestmessage_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <InputFilter
                                    latestmessage_filter={latestmessage_filter}
                                    setlatestmessage_filter={setlatestmessage_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Previous Message
                                 {message_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setmessage_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadMessage") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setmessage_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setmessage_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <InputFilter
                                    message_filter={message_filter}
                                    setmessage_filter={setmessage_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Updated By
                                 {leadholder_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setleadholder_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadHolder") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setleadholder_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setleadholder_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    leadholder_filter={leadholder_filter}
                                    setleadholder_filter={setleadholder_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Label
                                 {label_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setlabel_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadLabel") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setlabel_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setlabel_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    label_filter={label_filter}
                                    setlabel_filter={setlabel_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Lead Source
                                 {leadsource_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setleadsource_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadSource") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setleadsource_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setleadsource_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    leadsource_filter={leadsource_filter}
                                    setleadsource_filter={setleadsource_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Action
                                 {action_filter ? (
                                    <>
                                       <span
                                          className=""
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setaction_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadAction") == "" ? (
                                          <span
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setaction_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setaction_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    action_filter={action_filter}
                                    setaction_filter={setaction_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Lead Status
                                 {status_filter ? (
                                    <>
                                       <span
                                          className=""
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setstatus_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadStatus") == "" ? (
                                          <span
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setstatus_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setstatus_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    status_filter={status_filter}
                                    setstatus_filter={setstatus_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Date & Time
                                 {/* {date_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setdate_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {getFilterDate() && getFilterDate().from == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort3 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort4(true),
                                setSort3(false),
                                setSort(false),
                                setSort2(false),
                                getSearchedLeads(
                                  "followup_counter,asc",
                                  "sort"
                                )
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort4(true),
                                setSort3(false),
                                setSort(false),
                                setSort2(false),
                                getSearchedLeads(
                                  "followup_counter,asc",
                                  "sort"
                                )
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(970%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort3(true),
                                setSort4(false),
                                setSort(false),
                                setSort2(false),
                                getSearchedLeads(
                                  "followup_counter,asc",
                                  "sort"
                                )
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort4(true),
                                setSort3(false),
                                setSort(false),
                                setSort2(false),
                                getSearchedLeads(
                                  "followup_counter,desc",
                                  "sort"
                                )
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(970%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort4 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort4(false),
                                setSort3(true),
                                setSort(false),
                                setSort2(false),
                                getSearchedLeads(
                                  "followup_counter,desc",
                                  "sort"
                                )
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(970%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="last-filter">
                        <DateFilter
                          date_filter={date_filter}
                          setdate_filter={setdate_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div> */}
                              </div>
                           </th>
                        </tr>
                        {todayReport &&
                           todayReport.map((item, obj) => (
                              <TodayList
                              check={true}
                                 key={obj}
                                 data={item}
                                 i={obj}
                                 counter={counter}
                                 getTodayReport={getTodayReport}
                                 setLeadsSelectedIds={setLeadsSelectedIds}
                                 leadsSelectedIds={leadsSelectedIds}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
         {allPages !== [] ? (
            <Paginations
               data={allPages}
               paginate={paginate}
               perPage={getTodayReport}
            />
         ) : (
            ""
         )}
      </>
   )
}
export default TodayReport
